export const shopTypes = [
  'Auto Repair',
  'Auto Glass',
  'Auto Detail',
  'Auto Body & Collision',
  'Auto Oil Change',
  'Auto Tire Shop',
  'Bicycle Shop',
  'Car Audio & Electronic',
  'Custom Fabrication',
  'Medium & Heavy Duty (MD/HD) Shop',
  'Mobile Auto Repair',
  'Motorcycle shop',
  'Marine shop',
  'Performance shop',
  'RV Shop',
  'Other',
  'Aircraft',
  'Heavy Equipment',
  'Electric Vehicles',
  'Parts & Accessories',
];

export const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const numberOfEmployees = ['1-3', '3-6', '6-10', '10-20', '20+'];

export const baysCount = ['1-4', '5-9', '10+'];
